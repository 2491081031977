import { pushModal } from '@/components/modals'
import type { PageFile } from '@/routes/$workspaceSlug.$pageSlug/route'
import { parseWithZod } from '@conform-to/zod'
import {
  useFormAction,
  useMatch,
  useNavigation,
  useSearchParams,
} from '@remix-run/react'
import { useRevalidator } from '@remix-run/react'
import { type ClassValue, clsx } from 'clsx'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  format,
  formatDistance,
} from 'date-fns'
import React from 'react'
import { isPrefetch } from 'remix-utils/is-prefetch'
import { twMerge } from 'tailwind-merge'
import type { z } from 'zod'
import type { FileWithMeta } from './uploads/types'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function useMediaQuery(query: string) {
  const [value, setValue] = React.useState(false)

  React.useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches)
    }

    const result = matchMedia(query)
    result.addEventListener('change', onChange)
    setValue(result.matches)

    return () => result.removeEventListener('change', onChange)
  }, [query])

  return value
}

export const useIsDesktop = () => useMediaQuery('(min-width: 1024px)')
export const useIsMobile = () => !useIsDesktop()

export const useIsTabletAndAbove = () => useMediaQuery('(min-width:768px')

export function usePrevious<T>(state: T): T | undefined {
  const ref = React.useRef<T>()

  React.useEffect(() => {
    ref.current = state
  })

  return ref.current
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = 'POST',
  state = 'non-idle',
}: {
  formAction?: string
  formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
  state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
  const contextualFormAction = useFormAction()
  const navigation = useNavigation()
  const isPendingState =
    state === 'non-idle'
      ? navigation.state !== 'idle'
      : navigation.state === state
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  )
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit['headers'] | null | undefined>
) {
  const combined = new Headers()
  for (const header of headers) {
    if (!header) continue
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value)
    }
  }
  return combined
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get('X-Forwarded-Host') ??
    request.headers.get('host') ??
    new URL(request.url).host
  const protocol = request.headers.get('X-Forwarded-Proto') ?? 'http'
  return `${protocol}://${host}`
}

export function formatFileSize(bytes: number) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const size = bytes / k ** i
  return `${Math.round(size)} ${sizes[i]}`
}

export function combineSearchParams(
  searchParams: URLSearchParams,
  changes: Record<string, string | number | null>,
) {
  const newSearchParams = new URLSearchParams(searchParams)

  for (const [key, value] of Object.entries(changes)) {
    if (value) newSearchParams.set(key, String(value))
    if (value === null) newSearchParams.delete(key)
  }

  return newSearchParams
}

export function areSearchParamsEqual(
  first: URLSearchParams,
  second: URLSearchParams,
) {
  first.sort()
  second.sort()
  return first.toString() === second.toString()
}

export function useParsedSearchParams<Schema extends z.ZodSchema>(
  schema: Schema,
): z.infer<Schema> | undefined {
  const [searchParams] = useSearchParams()

  const memoizedParams = React.useMemo(() => {
    const parsedParams = parseWithZod(searchParams, { schema })

    return parsedParams.payload
  }, [searchParams, schema])

  return memoizedParams
}

export function prefetchCacheHeaders(request: Request) {
  const headers = new Headers()
  if (isPrefetch(request)) {
    headers.set('Cache-Control', 'private, max-age=5, smax-age=0')
  }
  return headers
}

export function useBlockWindowUnload(shouldBlock: boolean) {
  React.useEffect(() => {
    // Impossible to show custom modal.
    // https://stackoverflow.com/questions/276660/how-can-i-override-the-onbeforeunload-dialog-and-replace-it-with-my-own
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!shouldBlock) return

      event.preventDefault()
      event.returnValue = '' // This is needed for some browsers to show the default dialog
      return '' // This is needed for some browsers to show the default dialog
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [shouldBlock])
}

export function calculateTimeDifference(expiresAt: Date): string {
  const currentDate = new Date()

  const isExpired = currentDate > expiresAt

  if (isExpired) {
    const minutesAgo = differenceInMinutes(currentDate, expiresAt)
    if (minutesAgo < 60) {
      return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'}`
    }

    const hoursAgo = differenceInHours(currentDate, expiresAt)
    if (hoursAgo < 24) {
      return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'}`
    }

    const daysAgo = differenceInDays(currentDate, expiresAt)
    if (daysAgo <= 30) {
      return `${daysAgo} day${daysAgo === 1 ? '' : 's'}`
    }

    const monthsAgo = differenceInMonths(currentDate, expiresAt)
    if (monthsAgo < 12) {
      return `${monthsAgo} month${monthsAgo === 1 ? '' : 's'}`
    }

    const yearsAgo = differenceInYears(currentDate, expiresAt)
    return `${yearsAgo} year${yearsAgo === 1 ? '' : 's'}`
  }

  let daysLeft = differenceInDays(expiresAt, currentDate)
  if (differenceInHours(expiresAt, currentDate) % 24 > 0) {
    daysLeft += 1
  }

  if (daysLeft > 0) {
    return `${Math.ceil(daysLeft)} day${daysLeft === 1 ? '' : 's'}`
  }

  const hoursLeft = differenceInHours(expiresAt, currentDate)
  if (hoursLeft > 0) {
    return `${Math.abs(hoursLeft)} hour${hoursLeft === 1 ? '' : 's'}`
  }

  const minutesLeft = differenceInMinutes(expiresAt, currentDate)
  return `${Math.abs(minutesLeft)} minute${minutesLeft === 1 ? '' : 's'}`
}

export function calculateTimeSinceCreation(createdAt: Date): string {
  const currentDate = new Date()
  const daysAgo = formatDistance(createdAt, currentDate, { addSuffix: true })
  const formattedDate = format(createdAt, 'MMM-dd-yyyy hh:mm:ss aa XXX')

  return `${daysAgo} (${formattedDate})`
}

export function formatExpiryDate(date: Date) {
  const day = date.getDate()
  const daySuffix = getOrdinalSuffix(day)

  // Format the date using date-fns, then add the suffix manually
  return format(date, `MMM d'${daySuffix}' yyyy 'at' h:mm a 'CET'`)
}

function getOrdinalSuffix(day: number) {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const isPageFile = (
  file: PageFile | FileWithMeta | null,
): file is PageFile => (file as PageFile)?.checksum !== undefined

export function useRevalidateOnWindowFocus() {
  const revalidator = useRevalidator()

  const successPage = useMatch('/app/pages/success')

  React.useEffect(() => {
    if (typeof window === 'undefined') return

    const listener = () => {
      if (successPage) return
      revalidator.revalidate()
    }

    window.addEventListener('visibilitychange', listener, false)

    return () => {
      window.removeEventListener('visibilitychange', listener)
    }
  }, [revalidator.revalidate, successPage])
}
